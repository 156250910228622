import React, { useEffect, useState, useMemo } from 'react'
import { Box, Flex, Text, useBreakpointValue } from '@chakra-ui/react'
import keys from 'lodash/keys'
import { CurrencyHelpers } from '../../../../../helpers/currencyHelpers'
import { capitalizeFirstLetter } from '../../../../helpers/utils/string-utils'
import { FlatPrice } from '../../../flat-price'
import { SwatchSelectorConfiguration, SwatchSelectorGrouped } from '../../../types'
import { SWATCH_VIEW_VALUES } from '../../types'
import { SwatchSelector } from '../swatch-selector'

type SwatchContainerType = {
  swatchSelectorGroupedByPrice?: SwatchSelectorGrouped | any[]
  swatchViewType: string
  swatchSelector?: SwatchSelectorConfiguration
  setSelectedSwatch?: (swatch: string) => void
  isStoreDataLoading: boolean
}
export const SwatchContainer = ({
  swatchSelectorGroupedByPrice,
  swatchViewType,
  swatchSelector,
  setSelectedSwatch,
  isStoreDataLoading,
}: SwatchContainerType) => {
  const [hoveredComponentKey, setHoveredComponentKey] = React.useState<string>()
  const [hydrated, setHydrated] = useState(false)
  const isMobile = useBreakpointValue({ base: true, lg: false })
  const sortedSwatchSelectorGroupedByPrice = useMemo(() => {
    const sortedGroupedByPrice = Object.keys(swatchSelectorGroupedByPrice).reduce((acc, key) => {
      acc[key] = [...swatchSelectorGroupedByPrice[key]].sort(
        (a, b) => b?.inStock?.allSkuAvailableQuantity - a?.inStock?.allSkuAvailableQuantity,
      )
      return acc
    }, {})

    return sortedGroupedByPrice
  }, [swatchSelectorGroupedByPrice])

  useEffect(() => {
    setHydrated(true)
  }, [])

  return hydrated ? (
    <Flex
      gap={5}
      flexDirection={'column'}
      borderWidth={swatchViewType === SWATCH_VIEW_VALUES.LIST ? 1 : 0}
      borderRadius={{ base: 'md' }}
      borderColor={'surface.border'}
      {...(swatchViewType === SWATCH_VIEW_VALUES.LIST && {
        maxHeight: '288px',
        overflowY: 'auto',
        pt: 3,
        pb: 3,
      })}
    >
      {keys(sortedSwatchSelectorGroupedByPrice)
        ?.sort((priceA, priceB) => Number(priceB) - Number(priceA))
        ?.map((swatchDiscount) => {
          const swatch = sortedSwatchSelectorGroupedByPrice[swatchDiscount]
          const hasMoreThan1Price = keys(sortedSwatchSelectorGroupedByPrice).length > 1
          const basePrice = swatch[0]?.priceGroup?.basePrice?.centAmount
          const price = swatch[0]?.priceGroup?.price?.centAmount
          const discountPercent = swatch[0]?.priceGroup?.discountPercent

          const basePriceToRender =
            basePrice && basePrice !== price ? CurrencyHelpers.formatForCurrency(basePrice) : undefined
          const priceToRender = CurrencyHelpers.formatForCurrency(price)

          return (
            <Flex flexDirection={'column'} key={`price-group-${swatchDiscount}`} gap={2} flex={1}>
              {hasMoreThan1Price && (
                <FlatPrice
                  price={priceToRender}
                  basePrice={basePriceToRender}
                  discountPercent={discountPercent}
                  stackStyle={{
                    ...(swatchViewType === SWATCH_VIEW_VALUES.LIST && {
                      pl: 2.5,
                    }),
                  }}
                />
              )}

              <Flex
                gap={2.5}
                flexWrap={'wrap'}
                flexDirection={swatchViewType === SWATCH_VIEW_VALUES.LIST ? 'column' : 'row'}
              >
                {swatch.map((option, index) => {
                  const isSelected = option.value === swatchSelector?.attribute?.value

                  return (
                    <Box
                      key={`swatch-${swatchDiscount}-${index}`}
                      {...(swatchViewType === SWATCH_VIEW_VALUES.LIST && {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        _hover: {
                          backgroundColor: 'surface.muted',
                          cursor: !option?.inStock ? 'not-allowed' : 'pointer',
                        },
                        onClick: (event) => {
                          if (!option?.inStock) {
                            return
                          }
                          event.preventDefault()
                          event.stopPropagation()
                          setSelectedSwatch?.(option.value)
                        },
                      })}
                      onMouseEnter={() => setHoveredComponentKey(`swatch-${swatchDiscount}-${index}`)}
                      onMouseLeave={() => setHoveredComponentKey('')}
                    >
                      <SwatchSelector
                        option={option}
                        isSelected={isSelected}
                        isDisabled={!option?.inStock}
                        renderTooltip={swatchViewType !== SWATCH_VIEW_VALUES.LIST}
                        setSelection={() => setSelectedSwatch?.(option.value)}
                        wrapperStyle={{
                          ...(swatchViewType === SWATCH_VIEW_VALUES.LIST && {
                            pl: 2.5,
                            py: 1,
                          }),
                        }}
                        isHovered={hoveredComponentKey === `swatch-${swatchDiscount}-${index}`}
                        isStoreDataLoading={isStoreDataLoading}
                        isMobile={isMobile}
                      />
                      {swatchViewType === SWATCH_VIEW_VALUES.LIST && (
                        <Text textStyle={'body-75'} pl={{ base: 2 }}>
                          {capitalizeFirstLetter(option.colorDisplayName || option.value)}
                        </Text>
                      )}
                    </Box>
                  )
                })}
              </Flex>
            </Flex>
          )
        })}
    </Flex>
  ) : null
}
