import { Category } from '@Types/product/Category'
import { Product } from '@Types/product/Product'
import { RangePrice, Variant } from '@Types/product/Variant'
import { SwatchSelectorConfiguration, SwatchSelectorGrouped, TextSelectorOption } from '../types'
import { ContainerProps, StackProps } from '@chakra-ui/react'

export interface PdpLayoutProps {
  productId: string
  description: string
  isShowPromoExclusion: boolean
  isLoaded: boolean
  isHemmingChargeable: boolean
  main: JSX.Element
  price: JSX.Element
  itemPriceValue: number
  title: string
  accordions?: accordionItem[]
  mainStackProps?: StackProps
  rootProps?: ContainerProps
  stackProps?: StackProps
  childProducts?: Product[]
  currentChildProduct?: Product
  currentVariant?: Variant
  textSelectors?: {
    [key: string]: textSelectorProps
  }
  textSelectorsLabels?: {
    [key: string]: string
  }
  swatchSelector?: SwatchSelectorConfiguration
  swatchSelectorGroupedByPrice?: SwatchSelectorGrouped | any[]
  selectedSwatch?: string
  setSelectedSwatch?: (swatch: string) => void
  setSelectedAttribute?: (attribute: string, value: string) => void
  selectedAttributes?: {
    [key: string]: string
  }
  setSelectedSwatchUrl?: (swatch: string) => void
  setSelectedHemmingCharge?: (hemmingCharge: string) => void
  selectedHemmingCharge?: string
  selectedSwatchUrl?: string
  showMissingSelections?: boolean
  isProductInStock?: boolean
  category?: Category
  slug?: string
  product?: Product
  isMobileDevice: boolean
  algoliaQueryId?: string
  priceRange?: RangePrice
  currentPDPVariant?: any
  isStoreDataLoading: boolean
}

export type textSelectorProps = {
  attribute: {
    label: string
    value: string
  }
  options: TextSelectorOption[]
}

export type accordionItem = {
  defaultOpen: boolean
  label: string
  content: any
}

export enum SWATCH_VIEW_VALUES {
  GRID = 'grid',
  LIST = 'list',
}
