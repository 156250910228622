import { Box } from '@chakra-ui/react'
import React, { useEffect, useRef } from 'react'
import { PAYPAL_CLIENT_ID, PAYPAL_BASE_SRC_URL, PAYPAL_SRC_URL } from 'composable/components/general/constants'
declare global {
  interface Window {
    paypal: any
  }
}

export const PayLaterMessage = ({ totalPrice }) => {
  let totalOrder = totalPrice / 100
  const payaplMsgSrcUrl = `${PAYPAL_SRC_URL}&client-id=${PAYPAL_CLIENT_ID}` //`${PAYPAL_BASE_SRC_URL}&client-id=${PAYPAL_CLIENT_ID}&components=messages`
  const paypalRef = useRef<HTMLDivElement>(null)

  const paypalscriptload = () => {
    const script = document.createElement('script')
    script.src = payaplMsgSrcUrl
    script.async = true
    script.onload = () => renderPayLaterMessaging()
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }

  useEffect(() => {
    renderPayLaterMessaging()
  }, [totalOrder])

  const renderPayLaterMessaging = () => {
    const payPalCheckIntervalMessage = setInterval(() => {
      if (typeof window.paypal !== 'undefined' && paypalRef.current) clearInterval(payPalCheckIntervalMessage)
      window.paypal
        ?.Messages({
          amount: totalOrder.toFixed(2),
          placement: 'cart',
          style: {
            layout: 'text',
            color: 'black',
            ratio: '1x1',
            text: {
              size: 14,
            },
          },
        })
        .render(paypalRef.current)
    }, 200)
  }

  return <Box ref={paypalRef}></Box>
}
