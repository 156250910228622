import { Product } from '@Types/product/Product'
import { Variant } from '@Types/product/Variant'
import { MAX_ADD_TO_CART_COUNT } from 'composable/components/general/constants'
import { myBag } from 'frontastic/contexts/atgCartContext/helpers/constants'
import { raiseToast } from 'frontastic/contexts/atgCartContext/helpers/utils'

export const findUPCVariant = (upcCodeToMatch, childProducts) => {
  if (!upcCodeToMatch) {
    return { variant: null, product: null }
  }
  for (const childProduct of childProducts || []) {
    for (const variant of childProduct.variants || []) {
      const upcCodes = variant.attributes?.upcCodes
      if (Array.isArray(upcCodes) && upcCodes.includes(upcCodeToMatch) && variant.availableQuantity > 0) {
        return { variant, product: childProduct }
      }
    }
  }
  return { variant: null, product: null }
}

export const findURLOfChildProduct = (childProducts, urlSwatch) => {
  return childProducts?.find((childProduct: Product) => {
    const childProductSwatch = (childProduct?.variants?.[0]?.attributes?.ecommColor ?? '')
      .toLowerCase()
      .replaceAll(' ', '_')

    const urlSwatchLower = urlSwatch.toLowerCase().replaceAll(' ', '_')
    return childProductSwatch === urlSwatchLower
  })
}

export const findInitialChildProduct = (
  urlSwatch,
  urlSwatchChildProduct,
  firstAvailableChildProduct,
  childProducts,
) => {
  let colorSwatchInitial = ''
  childProducts?.forEach((product) => {
    if (product?.attributes?.isDefaultVariation) {
      colorSwatchInitial = product
    }
  })
  return urlSwatch &&
    urlSwatchChildProduct &&
    urlSwatchChildProduct?.variants?.some((variant: Variant) => variant?.availableQuantity > 0)
    ? urlSwatchChildProduct
    : colorSwatchInitial || firstAvailableChildProduct
}

export const findUrlSwatchChildProduct = (childProducts, urlSwatch: string) => {
  return childProducts?.find((childProduct: Product) => {
    const childProductSwatch = (childProduct?.variants?.[0]?.attributes?.ecommColor ?? '')
      .toLowerCase()
      .replaceAll(' ', '_')

    const urlSwatchLower = urlSwatch.toLowerCase().replaceAll(' ', '_')
    return childProductSwatch === urlSwatchLower
  })
}

export const showStoreList = (selectedAttributes, product, setShow) => {
  if (product.attributes?.segment2Label === '' && product.attributes?.segment3Label === '') {
    setShow(true)
  } else if (
    product.attributes?.segment2Label &&
    product.attributes?.segment3Label === '' &&
    selectedAttributes?.segment2
  ) {
    setShow(true)
  } else if (
    product.attributes?.segment2Label &&
    product.attributes?.segment3Label &&
    selectedAttributes?.segment2 &&
    selectedAttributes?.segment3
  ) {
    setShow(true)
  } else {
    setShow(false)
  }
}

export const setSelectedAttribute = (attribute: string, value: string, setSelectedAttributes) => {
  setSelectedAttributes((prev) => {
    const isAttributeAlreadySelected = prev[attribute] === value

    if (isAttributeAlreadySelected) {
      const newSelectedAttributes = { ...prev }
      delete newSelectedAttributes[attribute]
      return newSelectedAttributes
    } else {
      return { ...prev, [attribute]: value }
    }
  })
}

export const findVariantBySelectedAttributes = (
  selectedAttributes: { [key: string]: string },
  currentChildProduct?: Product,
) => {
  return currentChildProduct?.variants?.find((variant: Variant) => {
    const condition = Object.entries(selectedAttributes).every(([key, value]) => {
      return variant.attributes[key] === value
    })
    return condition && (variant?.availableQuantity > 0 || variant.availability?.backorderlevel > 0)
  })
}

export const triggerVariantChange = (
  selectedAttributes: { [key: string]: string },
  selectedChildProduct,
  setShowMissingSelections,
  variantCountInCartForShippingOrBOPIS,
  setQuantity,
  quantity,
  currentVariant,
  setCurrentVariant,
  formatMessage,
  toast,
) => {
  setShowMissingSelections(false)
  const selectedVariant = findVariantBySelectedAttributes(selectedAttributes, selectedChildProduct)
  const selectedVariantAvailableQuantity = selectedVariant?.availableQuantity
  const maxAvailableQty =
    selectedVariant?.availability?.backorderlevel > 0
      ? selectedVariant.availability.backorderlevel
      : selectedVariantAvailableQuantity - variantCountInCartForShippingOrBOPIS

  if (maxAvailableQty < quantity) {
    setQuantity(maxAvailableQty < 0 ? 0 : maxAvailableQty)
    if (maxAvailableQty > 0) {
      raiseToast(
        myBag,
        formatMessage({
          id: 'checkout.product.maxAvailableQuantity',
          values: { maxAvailableQty },
        }),
        'info',
        toast,
      )
    }
  } else if (quantity <= 0) {
    setQuantity(1) // reinitializing the quantity counter
  }
  const variantChangeCondition = selectedVariant && selectedVariant?.sku !== currentVariant?.sku
  if (variantChangeCondition) {
    setCurrentVariant(selectedVariant)
  }
}

export const triggerSwatchChange = (
  selectedSwatch: string,
  setShowMissingSelections,
  childProducts,
  currentChildProduct,
  setCurrentChildProduct,
  selectedAttributes,
  setSelectedAttributes,
  variantCountInCartForShippingOrBOPIS,
  setQuantity,
  quantity,
  currentVariant,
  setCurrentVariant,
  formatMessage,
  toast,
) => {
  setShowMissingSelections(false)
  const selectedChildProduct = childProducts?.find((childProduct: Product) => {
    return childProduct.variants?.every((variant: Variant) => {
      return variant.attributes?.ecommColor === selectedSwatch
    })
  })

  const swatchChangeCondition =
    selectedChildProduct && selectedChildProduct?.productId !== currentChildProduct?.productId

  if (swatchChangeCondition) {
    setCurrentChildProduct(selectedChildProduct)
    setSelectedAttributes({}) // Clear all selected attributes since the new Variant may not have available inventory
  }

  triggerVariantChange(
    selectedAttributes,
    selectedChildProduct,
    setShowMissingSelections,
    variantCountInCartForShippingOrBOPIS,
    setQuantity,
    quantity,
    currentVariant,
    setCurrentVariant,
    formatMessage,
    toast,
  )
}

type GetQuantityMaxParams = {
  currentVariant: Variant
  variantCountInCartForShippingOrBOPIS: number
  isBackordered: boolean
  stockIsSet: boolean
  isBOPISProduct: boolean
  BOPISQty?: number
}

export const getQuantityMax = ({
  currentVariant,
  isBackordered,
  stockIsSet,
  variantCountInCartForShippingOrBOPIS,
  isBOPISProduct,
  BOPISQty,
}: GetQuantityMaxParams) => {
  let availabilityQuantity = isBOPISProduct ? BOPISQty : currentVariant?.availableQuantity
  const backorderQty = isBOPISProduct ? 0 : currentVariant?.availability?.backorderlevel
  if (availabilityQuantity <= 0 && isBackordered) {
    availabilityQuantity = backorderQty
  } else if (availabilityQuantity > 0 && isBackordered) {
    availabilityQuantity = availabilityQuantity + backorderQty
  }

  const quantityMax = stockIsSet ? availabilityQuantity - variantCountInCartForShippingOrBOPIS : MAX_ADD_TO_CART_COUNT

  return quantityMax
}

export const areAllAttributesSelectedFunction = (availableAttributesTextSelectorsLabels, selectedAttributes) => {
  return Object.keys(availableAttributesTextSelectorsLabels)
    .filter((key) => availableAttributesTextSelectorsLabels[key] !== '')
    .every((attribute) => selectedAttributes[attribute])
}

export const allSkuAvailableQuantity = (variants) => {
  let totalCount = 0
  if (variants && variants.length > 0) {
    for (let i = 0; i < variants.length; i++) {
      let obj = variants[i]
      totalCount = totalCount + obj.availableQuantity
    }
  }
  return totalCount
}
